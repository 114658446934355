import React from "react";
import fb from "../fa.svg";
import inst from "../insta.svg";
import you from "../youtube.svg";
import twitter from "../twitter.svg";
import songsvg from "../sony-s.png";
import redfm from "../red fm logo.png";
import bridge from "../bridge.png";
import sportz from "../sportz.png";
import arrow from '../arrow.svg'

export default function Footer() {
  const footerlogo = "https://dev-upkl.upkl.in/files/logo.svg";
  const logo1 = "https://dev-upkl.upkl.in/files/Team-1.png";
  const logo2 = "https://dev-upkl.upkl.in/files/Team-2.png";
  const logo3 = "https://dev-upkl.upkl.in/files/Team-3.png";
  const logo4 = "https://dev-upkl.upkl.in/files/Team-4.png";
  const logo5 = "https://dev-upkl.upkl.in/files/Team-5.png";
  const logo6 = "https://dev-upkl.upkl.in/files/Team-6.png";
  const logo8 = "https://dev-upkl.upkl.in/files/Team-7.png";
  const logo7 = "https://dev-upkl.upkl.in/files/Team-8.png";
  return (
    <>
      <section className="sponsorsection">
        <div className="sponsor">
        <div className='titlecaption'>
        <div className='d-flex'>
          <div className='newarrows'>
          <h2 className='text-white'>PARTNERS</h2>
          <div className='linetilte'></div>

          </div>
          <div className='abc'>
            <img src={arrow} className='textrightarrow'/></div>  </div>
        </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 col-6 vertical-divider ">
                <div className="title">
                  <h6>BROADCASTER</h6>
                  <img src={songsvg} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-3 col-6 vertical-divider ">
                <div className="title">
                  <h6>RADIO PARTNERS</h6>
                  <img src={redfm} className="img-fluid" />
                </div>
              </div>{" "}
              <div className="col-md-3 col-6 vertical-divider ">
                <div className="title">
                  <h6>OUTREACH PARTNER</h6>
                  <img src={bridge} className="img-fluid" />
                </div>
              </div>{" "}
              <div className="col-md-3 col-6 ">
                <div className="title">
                  <h6>LEAGUE ORGANISER</h6>
                  <img src={sportz} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <footer>
          <div className="container footerline">
            <div className="row">
              <div className="col-md-6 col-12  align-self-center">
                <div className="footerrightmenu">
                  <ul className="d-flex p-0">
                    <li>
                      <img src={logo1} />
                    </li>
                    <li>
                      <img src={logo2} />
                    </li>
                    <li>
                      <img src={logo3} />
                    </li>
                    <li>
                      <img src={logo4} />
                    </li>
                    <li>
                      <img src={logo5} />
                    </li>
                    <li>
                      <img src={logo6} />
                    </li>
                    <li>
                      <img src={logo7} />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 col-12 align-self-center mobilenone">
                <div className="socialmenu">
                  <ul className="d-flex p-0">
                    <li>
                      <img src={fb} />
                    </li>
                    <li>
                      <img src={inst} />
                    </li>
                    <li>
                      <img src={you} />
                    </li>
                    <li>
                      <img src={twitter} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-12 offset-md-2 offset-0">
              <div className="footermenu">
                <div className="row">
                  <div className="col-md-2 col-4">
                    <p>Terms of Service</p>
                  </div>
                  <div className="col-md-2 col-4">
                    <p>Privacy Policy</p>
                  </div>
                  <div className="col-md-2 col-4">
                    <p>Terms of Service</p>
                  </div>
                  <div className="col-md-2 col-4">
                    <p>Company Details</p>
                  </div>
                  <div className="col-md-2 col-4">
                    <p>Sponsors </p>
                  </div>

                  <div className="col-md-2 col-4">
                    <p>Contact Us</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10 col-12 offset-md-1 offset-0">
            <div className="footerlogo">
              <img src={footerlogo} className="img-fluid" />
              <p className="footerlast">
                © 2024 UP Premier League. All rights reserved
              </p>
              <div className="footerline"></div>
            </div>
            <p className="policy">
              UPKL and the UPKL shield design are registered trademarks of the
              National Kabaddi League. The team names, logos and uniform designs
              are registered trademarks of the teams indicated. All other
              UPKL-related trademarks are trademarks of the National Kabaddi
              League.
            </p>
          </div>
        </footer>
      </section>
    </>
  );
}
