import React from "react";
import Footer from "../Include/Footer1";
import Nav from "../Include/Nav";
import teamlogo from "../footerlogo.svg";
import arrow from "../matcharrow.svg";
import arrow1 from "../arrow.svg";

function Matches() {
  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";

  return (
    <>
      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">MATCHES</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" />
            </div>{" "}
          </div>
        </div>

        
      </section>
      <section>
        <div className="matchstrip">
          <div className="container-fluid">
            <div className="titlecaption">
              <h2 className="matchhead">MAY 19 2024, SUNDAY</h2>
              <div className="linetilte"></div>
            </div>

            <div className="matchesbox">
              <div className="row">
                <div className="col-md-1 col-12 align-self-center">
                  <div className="matchleft">
                    <h5 className="finalmatch ">
                      MATCHES 01
                      <span className="rightarrowmatch desktopnone">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 29 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.611 7.60104C28.943 7.2691 28.943 6.7309 28.611 6.39896L23.2017 0.989592C22.8697 0.657647 22.3315 0.657647 21.9996 0.989592C21.6676 1.32154 21.6676 1.85973 21.9996 2.19167L26.8079 7L21.9996 11.8083C21.6676 12.1403 21.6676 12.6785 21.9996 13.0104C22.3315 13.3424 22.8697 13.3424 23.2017 13.0104L28.611 7.60104ZM0 7.85H28.01V6.15H0V7.85Z"
                            fill="white"
                          />
                        </svg>
                      </span>{" "}
                    </h5>
                  </div>
                  <div className="matchseparator desktopnone"></div>
                </div>
                <div className="col-md-8 col-12 ">
                  <div className="matchcenter  ">
                    <div className="row">
                      <div className="col-md-2 col-2 align-self-center">
                        <img src={teamlogo} className="float-right img-fluid" />
                      </div>

                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 p-0 p-sm-0">
                        <div className="match-score-tl">
                          <h5>FT</h5>
                        </div>
                        <div className="match-score">
                          <h5>3-2</h5>
                        </div>
                      </div>
                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 align-self-center ">
                        <img src={teamlogo} className="float-left img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12 align-self-center">
                  <div className="row">
                    <div className="col-md-8 col-12">
                      <div className="matchright">
                        <p>Noida Indoor Stadium, Noida, UP</p>
                      </div>
                    </div>
                    <div className="col-4 d-none d-md-block d-lg-block">
                      <center>
                        <div className="matchlogotop">
                          <div className="matchlogo">
                            <img src={teamlogo} />
                          </div>
                        </div>
                      </center>
                      <img src={arrow} className="img-fluid matcharrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="matchesbox">
              <div className="row">
                <div className="col-md-1 col-12 align-self-center">
                  <div className="matchleft">
                    <h5 className="finalmatch ">
                      MATCHES 01
                      <span className="rightarrowmatch desktopnone">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 29 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.611 7.60104C28.943 7.2691 28.943 6.7309 28.611 6.39896L23.2017 0.989592C22.8697 0.657647 22.3315 0.657647 21.9996 0.989592C21.6676 1.32154 21.6676 1.85973 21.9996 2.19167L26.8079 7L21.9996 11.8083C21.6676 12.1403 21.6676 12.6785 21.9996 13.0104C22.3315 13.3424 22.8697 13.3424 23.2017 13.0104L28.611 7.60104ZM0 7.85H28.01V6.15H0V7.85Z"
                            fill="white"
                          />
                        </svg>
                      </span>{" "}
                    </h5>
                  </div>
                  <div className="matchseparator desktopnone"></div>
                </div>
                <div className="col-md-8 col-12 ">
                  <div className="matchcenter  ">
                    <div className="row">
                      <div className="col-md-2 col-2 align-self-center">
                        <img src={teamlogo} className="float-right img-fluid" />
                      </div>

                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 p-0 p-sm-0">
                        <div className="match-score-tl">
                          <h5>FT</h5>
                        </div>
                        <div className="match-score">
                          <h5>3-2</h5>
                        </div>
                      </div>
                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 align-self-center ">
                        <img src={teamlogo} className="float-left img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12 align-self-center">
                  <div className="row">
                    <div className="col-md-8 col-12">
                      <div className="matchright">
                        <p>Noida Indoor Stadium, Noida, UP</p>
                      </div>
                    </div>
                    <div className="col-4 d-none d-md-block d-lg-block">
                      <center>
                        <div className="matchlogotop">
                          <div className="matchlogo">
                            <img src={teamlogo} />
                          </div>
                        </div>
                      </center>
                      <img src={arrow} className="img-fluid matcharrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="matchesbox">
              <div className="row">
                <div className="col-md-1 col-12 align-self-center">
                  <div className="matchleft">
                    <h5 className="finalmatch ">
                      MATCHES 01
                      <span className="rightarrowmatch desktopnone">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 29 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.611 7.60104C28.943 7.2691 28.943 6.7309 28.611 6.39896L23.2017 0.989592C22.8697 0.657647 22.3315 0.657647 21.9996 0.989592C21.6676 1.32154 21.6676 1.85973 21.9996 2.19167L26.8079 7L21.9996 11.8083C21.6676 12.1403 21.6676 12.6785 21.9996 13.0104C22.3315 13.3424 22.8697 13.3424 23.2017 13.0104L28.611 7.60104ZM0 7.85H28.01V6.15H0V7.85Z"
                            fill="white"
                          />
                        </svg>
                      </span>{" "}
                    </h5>
                  </div>
                  <div className="matchseparator desktopnone"></div>
                </div>
                <div className="col-md-8 col-12 ">
                  <div className="matchcenter  ">
                    <div className="row">
                      <div className="col-md-2 col-2 align-self-center">
                        <img src={teamlogo} className="float-right img-fluid" />
                      </div>

                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 p-0 p-sm-0">
                        <div className="match-score-tl">
                          <h5>FT</h5>
                        </div>
                        <div className="match-score">
                          <h5>3-2</h5>
                        </div>
                      </div>
                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 align-self-center ">
                        <img src={teamlogo} className="float-left img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12 align-self-center">
                  <div className="row">
                    <div className="col-md-8 col-12">
                      <div className="matchright">
                        <p>Noida Indoor Stadium, Noida, UP</p>
                      </div>
                    </div>
                    <div className="col-4 d-none d-md-block d-lg-block">
                      <center>
                        <div className="matchlogotop">
                          <div className="matchlogo">
                            <img src={teamlogo} />
                          </div>
                        </div>
                      </center>
                      <img src={arrow} className="img-fluid matcharrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="titlecaption">
              <h2 className="matchhead">MAY 19 2024, SUNDAY</h2>
              <div className="linetilte"></div>
            </div>

            <div className="matchesbox">
              <div className="row">
                <div className="col-md-1 col-12 align-self-center">
                  <div className="matchleft">
                    <h5 className="finalmatch ">
                      MATCHES 01
                      <span className="rightarrowmatch desktopnone">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 29 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.611 7.60104C28.943 7.2691 28.943 6.7309 28.611 6.39896L23.2017 0.989592C22.8697 0.657647 22.3315 0.657647 21.9996 0.989592C21.6676 1.32154 21.6676 1.85973 21.9996 2.19167L26.8079 7L21.9996 11.8083C21.6676 12.1403 21.6676 12.6785 21.9996 13.0104C22.3315 13.3424 22.8697 13.3424 23.2017 13.0104L28.611 7.60104ZM0 7.85H28.01V6.15H0V7.85Z"
                            fill="white"
                          />
                        </svg>
                      </span>{" "}
                    </h5>
                  </div>
                  <div className="matchseparator desktopnone"></div>
                </div>
                <div className="col-md-8 col-12 ">
                  <div className="matchcenter  ">
                    <div className="row">
                      <div className="col-md-2 col-2 align-self-center">
                        <img src={teamlogo} className="float-right img-fluid" />
                      </div>

                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 p-0 p-sm-0">
                        <div className="match-score-tl">
                          <h5>FT</h5>
                        </div>
                        <div className="match-score">
                          <h5>3-2</h5>
                        </div>
                      </div>
                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 align-self-center ">
                        <img src={teamlogo} className="float-left img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12 align-self-center">
                  <div className="row">
                    <div className="col-md-8 col-12">
                      <div className="matchright">
                        <p>Noida Indoor Stadium, Noida, UP</p>
                      </div>
                    </div>
                    <div className="col-4 d-none d-md-block d-lg-block">
                      <center>
                        <div className="matchlogotop">
                          <div className="matchlogo">
                            <img src={teamlogo} />
                          </div>
                        </div>
                      </center>
                      <img src={arrow} className="img-fluid matcharrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="matchesbox">
              <div className="row">
                <div className="col-md-1 col-12 align-self-center">
                  <div className="matchleft">
                    <h5 className="finalmatch ">
                      MATCHES 01
                      <span className="rightarrowmatch desktopnone">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 29 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.611 7.60104C28.943 7.2691 28.943 6.7309 28.611 6.39896L23.2017 0.989592C22.8697 0.657647 22.3315 0.657647 21.9996 0.989592C21.6676 1.32154 21.6676 1.85973 21.9996 2.19167L26.8079 7L21.9996 11.8083C21.6676 12.1403 21.6676 12.6785 21.9996 13.0104C22.3315 13.3424 22.8697 13.3424 23.2017 13.0104L28.611 7.60104ZM0 7.85H28.01V6.15H0V7.85Z"
                            fill="white"
                          />
                        </svg>
                      </span>{" "}
                    </h5>
                  </div>
                  <div className="matchseparator desktopnone"></div>
                </div>
                <div className="col-md-8 col-12 ">
                  <div className="matchcenter  ">
                    <div className="row">
                      <div className="col-md-2 col-2 align-self-center">
                        <img src={teamlogo} className="float-right img-fluid" />
                      </div>

                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 p-0 p-sm-0">
                        <div className="match-score-tl">
                          <h5>FT</h5>
                        </div>
                        <div className="match-score">
                          <h5>3-2</h5>
                        </div>
                      </div>
                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 align-self-center ">
                        <img src={teamlogo} className="float-left img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12 align-self-center">
                  <div className="row">
                    <div className="col-md-8 col-12">
                      <div className="matchright">
                        <p>Noida Indoor Stadium, Noida, UP</p>
                      </div>
                    </div>
                    <div className="col-4 d-none d-md-block d-lg-block">
                      <center>
                        <div className="matchlogotop">
                          <div className="matchlogo">
                            <img src={teamlogo} />
                          </div>
                        </div>
                      </center>
                      <img src={arrow} className="img-fluid matcharrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="matchesbox">
              <div className="row">
                <div className="col-md-1 col-12 align-self-center">
                  <div className="matchleft">
                    <h5 className="finalmatch ">
                      MATCHES 01
                      <span className="rightarrowmatch desktopnone">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 29 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.611 7.60104C28.943 7.2691 28.943 6.7309 28.611 6.39896L23.2017 0.989592C22.8697 0.657647 22.3315 0.657647 21.9996 0.989592C21.6676 1.32154 21.6676 1.85973 21.9996 2.19167L26.8079 7L21.9996 11.8083C21.6676 12.1403 21.6676 12.6785 21.9996 13.0104C22.3315 13.3424 22.8697 13.3424 23.2017 13.0104L28.611 7.60104ZM0 7.85H28.01V6.15H0V7.85Z"
                            fill="white"
                          />
                        </svg>
                      </span>{" "}
                    </h5>
                  </div>
                  <div className="matchseparator desktopnone"></div>
                </div>
                <div className="col-md-8 col-12 ">
                  <div className="matchcenter  ">
                    <div className="row">
                      <div className="col-md-2 col-2 align-self-center">
                        <img src={teamlogo} className="float-right img-fluid" />
                      </div>

                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 p-0 p-sm-0">
                        <div className="match-score-tl">
                          <h5>FT</h5>
                        </div>
                        <div className="match-score">
                          <h5>3-2</h5>
                        </div>
                      </div>
                      <div className="col-3 align-self-center">
                        <div className="teamname">
                          <h6>YAMUNA YODHAS</h6>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 align-self-center ">
                        <img src={teamlogo} className="float-left img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12 align-self-center">
                  <div className="row">
                    <div className="col-md-8 col-12">
                      <div className="matchright">
                        <p>Noida Indoor Stadium, Noida, UP</p>
                      </div>
                    </div>
                    <div className="col-4 d-none d-md-block d-lg-block">
                      <center>
                        <div className="matchlogotop">
                          <div className="matchlogo">
                            <img src={teamlogo} />
                          </div>
                        </div>
                      </center>
                      <img src={arrow} className="img-fluid matcharrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Matches;
