import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import arrow from "../arrow.svg";
import border from "../border.svg";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


export default function Tv() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/UPKL%20TV?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedData = data.data.sort((a, b) => a.position - b.position);
        setStandings(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []); // Empty dependency array means this effect runs only once, after the initial render

  const options3 = {
    loop: true,
    margin: 20,
    nav: false,
    loop: true,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 4200,
    responsive: {
      0: {
        items: 1.6,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <>
      <div className="playerstats">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL TV</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" />
            </div>
          </div>
        </div>
        <div className="row">
          <OwlCarousel className="owl-theme" {...options3}>
            {standings.length > 0 ? (
              standings.map((team, index) => (
                <div class="image-container">
                  <div class="overlay">
                    <img src={border} className="img-fluid bordernew" />
                    <div class="text">
                      <h5>{team.title}</h5>
                      <div className="divider"></div>
                      <p>May 19, 2024</p>
                    </div>
                  </div>
                  <img
                    src={`https://dev-upkl.upkl.in/${team.image_banner}`}
                    alt={team.title}
                    class="image"
                  />
                </div>
              ))
            ) : (
                <SkeletonTheme baseColor="#292679" highlightColor="#39358b70">

                <div  className="image-container">
                <div className="overlay">
                </div>
                <Skeleton width={240} height={300} /> {/* Adjust width and height as per your design */}
              </div>
              </SkeletonTheme>
            )}
          </OwlCarousel>
        </div>
      </div>
    </>
  );
}
