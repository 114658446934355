// React Component
import React ,{useState,useEffect} from 'react';
import '../App.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import team from '../team.svg'
import arrow from '../arrow.svg'
import tv from '../teambg.svg'
import Nav from '../Include/Nav';
import Footer from '../Include/Footer1';
import news from '../newsletter.svg'
import newleft from '../newsletter-img.png'
import borderimg from '../boxborder.svg'
import stadium from '../stadium.svg'
import 'react-loading-skeleton/dist/skeleton.css'
import StandingsTable from './StandingsTable';
import Tv from './Tv';
const Home = () => {
  const [standings, setStandings] = useState([]);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch('https://dev-upkl.upkl.in/api/resource/Standings?fields=[%22*%22]', {
          headers: {
            'Cookie': 'full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image='
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch standings');
        }
        const data = await response.json();
        setStandings(data);
      } catch (error) {
        console.error('Error fetching standings:', error);
      }
    };

    fetchStandings();
  }, []);

  
  const Banner = "https://dev-upkl.upkl.in/files/Rectangle 2.jpg";
 
  const playerbanner = "https://dev-upkl.upkl.in/files/Rectangle 26 (1).png";


  
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSliderChange = event => {
    setCurrentIndex(event.item.index);
  };


  const renderContent = index => {
    switch (index) {
      case 0:
        return (
          <div>
            <h2>Content Title 1</h2>
            <p>Content for Slide 1</p>
          </div>
        );
      case 1:
        return (
          <div>
            <h2>Content Title 2</h2>
            <p>Content for Slide 2</p>
          </div>
        );
      // Add more cases as needed
      default:
        return null;
    }
  };


    
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    loop:true,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Autoplay interval in milliseconds (4 seconds)
    responsive: {
      0: {
        items: 1.4,
    margin: 5,

      },
      600: {
        items: 3
      },
      1000: {
        items: 4.5
      }
    }
  };

  const options2 = {
    loop: true,
    margin: 20,
    nav: false,
    loop:true,
    autoplay: true, // Enable autoplay
    
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1.6

      },
      600: {
        items: 3
      },
      1000: {
        items: 3.5
      }
    }
  };

  const options3 = {
    loop: true,
    margin: 20,
    nav: false,
    loop:true,
    dots:false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 4200,
    responsive: {
      0: {
        items: 1.6,

      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };

  const options4 = {
    items:1,
    loop:true,
    autoplay:true,
     dots:false,
    autoplayTimeout:4200,
    autoplayHoverPause:false,
    animateOut: 'fadeOut', // Fade out animation
    animateIn: 'fadeIn', // Fade in animation
    responsive: {
      0: {
        items: 1,

      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  const options5 = {
    loop: true,
    margin: 20,
    nav: false,
    loop:true,
    dots:false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 4500,
      autoplayHoverPause:false,
      animateOut: 'fadeOut', // Fade out animation
      animateIn: 'fadeIn', // Fade in animation
    responsive: {
      0: {
        items: 1,

      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  return (
    <>
   

<Nav/>



       <div className="banner-container">
        <div className="banner-overlay"></div> {/* Blue overlay */}
        <img src={Banner} alt="Banner" className="banner-image" />
        <div className="banner-caption">
          <h2>Engaging and Enthralling - Best Matches from UPKL Team 2024 <img src={arrow} className='arrow img-fluid'/></h2>  
          <span className='line'></span>
          <p>May 29, 2024 </p>

        </div>
      </div> 



      <section className='fixturessection'>

        <div className='titlecaption'>
        <div className='d-flex'>
          <div className='newarrows'>
          <h2 className='text-white'>FIXTURES</h2>
          <div className='linetilte'></div>

          </div>
          <div className='abc'>
            <img src={arrow} className='textrightarrow'/></div>  </div>
        </div>

        <OwlCarousel className="owl-theme" {...options}>
          <div className="item">
            <div className='fixturehome'>
              <div className='container-fluid'>
                <div className="row">
                    <div className='fixturecard'>
                      <h6 className='daymatch'>DAY 01, MATCH 02</h6>
                      <p>MAY 19 2024, <b>SUNDAY</b> <br></br>21:00, Saudi Arabia</p>
                      <div className='row'>
                        <div className='col-4'>
                          <div className='fixtureleft'>
                            <img src={team} className='img-fluid' alt="Logo"/>
                          </div>
                        </div>
                        <div className='col-4 p-0'>
                          <div className='fixturecenter'>

                            <div className='scorebox'>
                              <h5>3 5</h5>
                            </div>
                          </div>
                        </div>

                        <div className='col-4'>
                          <div className='fixtureleft'>

                            <img src={team} className='img-fluid' alt="Logo"/>
                          </div>

                        </div>

                      </div>


                      <div className='row'>
                        <div className='col-5'>
                          <div className='fixtureleft'>
                          <h3>BRIE STARS</h3>
                          </div>
                        </div>
                        <div className='col-2'>
                          <div className='fixturecenter'>

                           
                            <h3>VS</h3>
                          </div>
                        </div>

                        <div className='col-5'>
                          <div className='fixtureleft'>

                            <h3>BRIE STARS</h3>
                          </div>

                        </div>

                      </div>

                      <button className='moreinfo'>MATCH INFO</button>
                    </div>
                </div>

              </div>
            </div>

          </div>



          <div className="item">
            <div className='fixturehome'>
              <div className='container-fluid'>
                <div className="row">
                    <div className='fixturecard'>
                      <h6 className='daymatch'>DAY 01, MATCH 02</h6>
                      <p>MAY 19 2024, <b>SUNDAY</b> <br></br>21:00, Saudi Arabia</p>
                      <div className='row'>
                        <div className='col-4'>
                          <div className='fixtureleft'>
                            <img src={team} className='img-fluid' alt="Logo"/>
                          </div>
                        </div>
                        <div className='col-4 p-0'>
                          <div className='fixturecenter'>

                            <div className='scorebox'>
                              <h5>3 5</h5>
                            </div>
                          </div>
                        </div>

                        <div className='col-4'>
                          <div className='fixtureleft'>

                            <img src={team} className='img-fluid' alt="Logo"/>
                          </div>

                        </div>

                      </div>


                      <div className='row'>
                        <div className='col-5'>
                          <div className='fixtureleft'>
                          <h3>BRIE STARS</h3>
                          </div>
                        </div>
                        <div className='col-2'>
                          <div className='fixturecenter'>

                           
                            <h3>VS</h3>
                          </div>
                        </div>

                        <div className='col-5'>
                          <div className='fixtureleft'>

                            <h3>BRIE STARS</h3>
                          </div>

                        </div>

                      </div>

                      <button className='moreinfo'>MATCH INFO</button>
                    </div>
                </div>

              </div>
            </div>

          </div>


          <div className="item">
            <div className='fixturehome'>
              <div className='container-fluid'>
                <div className="row">
                    <div className='fixturecard'>
                      <h6 className='daymatch'>DAY 01, MATCH 02</h6>
                      <p>MAY 19 2024, <b>SUNDAY</b> <br></br>21:00, Saudi Arabia</p>
                      <div className='row'>
                        <div className='col-4'>
                          <div className='fixtureleft'>
                            <img src={team} className='img-fluid' alt="Logo"/>
                          </div>
                        </div>
                        <div className='col-4 p-0'>
                          <div className='fixturecenter'>

                            <div className='scorebox'>
                              <h5>3 5</h5>
                            </div>
                          </div>
                        </div>

                        <div className='col-4'>
                          <div className='fixtureleft'>

                            <img src={team} className='img-fluid' alt="Logo"/>
                          </div>

                        </div>

                      </div>


                      <div className='row'>
                        <div className='col-5'>
                          <div className='fixtureleft'>
                          <h3>BRIE STARS</h3>
                          </div>
                        </div>
                        <div className='col-2'>
                          <div className='fixturecenter'>

                           
                            <h3>VS</h3>
                          </div>
                        </div>

                        <div className='col-5'>
                          <div className='fixtureleft'>

                            <h3>BRIE STARS</h3>
                          </div>

                        </div>

                      </div>

                      <button className='moreinfo'>MATCH INFO</button>
                    </div>
                </div>

              </div>
            </div>

          </div>

        </OwlCarousel>

      </section>



<StandingsTable/>

{/* <section className='playerstatssection'>

<div className='titlecaption'>
  <h2>PLAYER STATS</h2>
  <div className='linetilte'></div>
</div>
<OwlCarousel className="owl-theme" {...options1}>
  <div className="itemstats">
 <div className='playerstats'>
  <div className='topsection'>
   <div className='row'>
   <div className='col-6'>
    <img src={logo} className='img-fluid logoteam'/>
     <div className='playername'>
     <h5>VINAY<br></br>
PRADHAN</h5>
     </div>
    </div>
    <div className='col-6'>
      <img src={player} className='img-fluid  playerimg'/>
    </div>
   </div>

  </div>
  <div className='bottomsection'>
    <div className='row'>
      <div className='col-4'>
        <div className='stats'>
          <h5>MATCHES</h5>
          <h3>112</h3>
        </div>
      </div>
      <div className='col-2'>
      <div className='stats'>
          <h5>WIN</h5>
          <h3>87</h3>

        </div>
      </div>
      <div className='col-6'>
      <div className='stats'>
          <h5>HIGHEST SCORE</h5>
          <h3>67</h3>

        </div>
      </div>

    </div>
  </div>
 </div>

  </div>

</OwlCarousel>

</section> */}


<section className='newletter'>
<div className='bluenewletter'>
  <div className='row'>
    <div className='col-md-6 col-12'>
    <OwlCarousel className="owl-theme" {...options4}>
      <img src={playerbanner} className='img-fluid'/>

      <img src={news} className='img-fluid'/>
      </OwlCarousel>
    </div>
    <div className='col-md-6 col-12 align-sel'>
    <OwlCarousel className="owl-theme" {...options5}>
     
 <div className='newletterright'>

        <h5>Three New Entries And Who<br></br> Tops The Table</h5>
        <div className='line'></div>
        <p >A Yamuna Yodhas and Noida Ninjas ended their campaign on a thrilling note as they shared points in a gripping 45-45 draw in a Uttar Pradesh Kabaddi League</p>


        <div className='row'>
          <div className='newlogos'>
            <img src={team} className='img-fluid' />
            <div className='date'>19 May, 2024</div>
          </div>
        </div>
      </div>

      <div className='newletterright'>

<h5>Update New Entries And Who<br></br> Tops The Table</h5>
<div className='line'></div>
<p >A Yamuna Yodhas and Noida Ninjas ended their campaign on a thrilling note as they shared points in a gripping 45-45 draw in a Uttar Pradesh Kabaddi League</p>
<div className='row'>
  <div className='newlogos'>
    <img src={team} className='img-fluid' />
    <div className='date'>19 May, 2024</div>
  </div>
</div>
</div>
      </OwlCarousel>
    </div>

  </div>
</div>


<Tv/>


</section>
<section className='kabaddiman'>
<div className='row'>
  <div className='col-md-7 col-12'></div>
  <div className='col-md-5 col-12'>
  <div className='newletterright'>  

<h5>Rahul Chaudhary: After ruling the roost for six seasons</h5>
<div className='line'></div>
<p>Yamuna Yodhas and Noida Ninjas ended their campaign on a thrilling note as they shared points in a gripping 45-45 draw in a Uttar Pradesh Kabaddi League</p>


<div className='row'>
  <div className='newlogos'>
    <img src={team} className='img-fluid' />
    <div className='date'>19 May, 2024</div>
  </div>
</div>
</div>
<img src={borderimg} className='img-fluid borderimg'/>
  </div>
</div>
  </section>
  <section>
  <img src={stadium}  className='img-fluid w-100'/>
  </section>

<section className='tvsection'>

<div className='titlecaption'>
        <div className='d-flex'>
          <div className='newarrows'>
          <h2 className='text-white'>LATEST NEWS</h2>
          <div className='linetilte'></div>

          </div>
          <div className='abc'>
            <img src={arrow} className='textrightarrow'/></div>  </div>
        </div>

<OwlCarousel className="owl-theme" {...options3}>

  <div class="card">
  <img class="card-img-top" src={tv} alt="Card image cap"/>
  <div class="card-body">
    <img src={arrow}   className='img-fluid newarrow1'/>
  <p class="card-text">Noida Ninjas <span className='text-white datetv'>| 21:00, Saudi Arabia</span></p>

    <h5 class="card-title">Noida Ninjas In Semi Finals And Defeating Challengers</h5>
  </div>
</div>

</OwlCarousel>


<div className='newsbottom'>
<div className='row'>
  <div className='col-md-6 col-12'>

  <div class="image-container">
  <div class="overlay">

    <div class="text"><h5>Engaging and Enthralling - Best Matches from UPKL Teams 2024</h5>
    <div className='divider'></div>
    <p>May 19, 2024</p>
    
    </div>
  </div>
  <img src={news} alt="Your Image" class="image"/>
</div>

  </div>

  <div className='col-md-6 col-12'>
    <div className='newlist'>
      <div className='row'>
        <div className='col-md-3 col-4'>
          <div className='leftimgnews'>
            <img src={newleft} className='img-fluid' />
          </div>
        </div>
        <div className='col-md-9 col-8'>
         <div className='rightcontentnew'>
         <div className='new-title'>
            <h6>Engaging and Enthralling - Best Matches from UPKL Teams 2024 Season</h6>
<div className='bottompara'>
<p>Noida Ninjas <span>| 21:00, Saudi Arabia</span></p>

</div>
          </div>
         </div>
        </div>

      </div>
    </div>
    <div className='separaternews'></div>


    <div className='newlist'>
      <div className='row'>
        <div className='col-md-3 col-4'>
          <div className='leftimgnews'>
            <img src={newleft} className='img-fluid' />
          </div>
        </div>
        <div className='col-md-9 col-8'>
         <div className='rightcontentnew'>
         <div className='new-title'>
            <h6>Engaging and Enthralling - Best Matches from UPKL Teams 2024 Season</h6>
<div className='bottompara'>
<p>Noida Ninjas <span>| 21:00, Saudi Arabia</span></p>

</div>
          </div>
         </div>
        </div>

      </div>
    </div>

    <div className='separaternews'></div>

    <div className='newlist'>
      <div className='row'>
        <div className='col-md-3 col-4'>
          <div className='leftimgnews'>
            <img src={newleft} className='img-fluid' />
          </div>
        </div>
        <div className='col-md-9 col-8'>
         <div className='rightcontentnew'>
         <div className='new-title'>
            <h6>Engaging and Enthralling - Best Matches from UPKL Teams 2024 Season</h6>
<div className='bottompara'>
<p>Noida Ninjas <span>| 21:00, Saudi Arabia</span></p>

</div>
          </div>
         </div>
        </div>

      </div>
    </div>


  </div>

</div>
</div>
  </section> 




<Footer/>
    </>
  );
}

export default Home;
