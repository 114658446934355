import React from "react";
import Nav from "../Include/Nav";
import aboutfirst from "./aboutusleft.svg";
import icon from "./icon.svg";
import Footer from "../Include/Footer1";
import arrow from "../arrow.svg";
import founder from '../UPKL FOUNDER (3).jpg'
export default function About() {
  // const founder ="https://dev-upkl.upkl.in/files/rahul choudhary.jpg";
  const breadcumb = "https://dev-upkl.upkl.in/files/breadcumb1.png";
  return (
    <>
      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">ABOUT US</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" />
            </div>{" "}
          </div>
        </div>
      </section>
      <section className="aboutsection">
        <div className="container-fluid">
        <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white"> WHO WE ARE</h2>
                <div className="linetilte"></div>
              </div>
              <div className="abc">
                <img src={arrow} className="textrightarrow" />
              </div>{" "}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12">
              <div className="whoweareleft">
                <img src={aboutfirst} className="img-fluid" />
              </div>
            </div>

            <div className="col-md-6 col-12 align-self-center">
              <div className="aboutuspara">
                <p>
                  The Uttar Pradesh Kabaddi League (UPKL) stands as a pioneering
                  initiative aimed at reinvigorating the significance of Kabaddi
                  in India. Serving as a dedicated platform, UPKL is committed
                  to fostering the talents of local Kabaddi athletes, providing
                  them with opportunities for growth and recognition.
                </p>
                <p>
                  Beyond empowering these athletes, UPKL endeavors to captivate
                  a wide audience, ensuring the continued growth and
                  entertainment value of Kabaddi throughout the nation. With its
                  focus on talent development and audience engagement, UPKL
                  emerges as a vital force in revitalizing the spirit of Kabaddi
                  and solidifying its position as a beloved sport in India.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="aboutseparate"></div>
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">MISSION VISION</h2>
                <div className="linetilte"></div>
              </div>
              <div className="abc">
                <img src={arrow} className="textrightarrow" />
              </div>{" "}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12">
              <div className="ourmissionbox">
                <div className="title">MISSION</div>
                <p>
                  With a resolute mission to elevate Kabaddi's national
                  standing, we aim to propel the sport into the spotlight.
                  Through strategic initiatives and collaborative efforts, we
                  will strive to broaden Kabaddi's appeal, fostering increased
                  participation and cultivating a passionate fanbase. This
                  multi-pronged approach will ensure Kabaddi's enduring
                  popularity and growth within the sporting landscape
                </p>
              </div>
            </div>

            <div className="col-md-6 col-12 align-self-center">
              <div className="ourmissionbox">
                <div className="title">VISION</div>
                <p>
                  Our vision is to elevate Kabaddi to the status of a beloved
                  household sport, akin to national favourites. We endeavour to
                  cultivate a culture where Kabaddi transcends mere viewership
                  and becomes an integral part of daily life for people of all
                  ages. This widespread embrace will solidify Kabaddi's position
                  as a cherished national pastime.
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="videoplayer">
        <div class="custom-container">
          <div class="custom-background-image"></div>
          <div class="custom-overlay"></div>
          <div class="custom-content">
            <h1>
              WATCH THE AMAZING <br></br>TRAINING ATMOSPHERE<br></br>AT OUR CLUB
              WITH TALENT COACHES
            </h1>
          </div>
          <div class="custom-play-button">
            <img src={icon} className="img-fluid" />
          </div>
        </div>
      </section>

      <section className="aboutfounder">
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">FOUNDERS MESSAGE</h2>
                <div className="linetilte"></div>
              </div>
              <div className="abc">
                <img src={arrow} className="textrightarrow" />
              </div>{" "}
            </div>
          </div>

          <div className="founderbox">
            <div className="row">
              <div className="col-md-5 col-12 align-self-center">
               <div className="founderright">
               <div className="founderimg">
                  <img src={founder} className="img-fluid" />
                </div>
                <div className="foundermessage ">
                 
                </div>
               </div>
              </div>
              <div className="col-md-7 col-12 align-self-center">
                <div className="foundermessage foundermessage1">
                <p>
                    Welcome to the Uttar Pradesh Kabaddi League (UPKL), a
                    dynamic platform dedicated to the revival and celebration of
                    Kabaddi in the heart of India. As the founder of UPKL, it
                    brings me immense pride to introduce you to our endeavour
                    aimed at rekindling the spirit of this traditional sport.{" "}
                  </p>
                  <p>
                    Our mission is to elevate Kabaddi to new heights of
                    popularity and prominence by engaging a diverse audience,
                    fostering talent at the grassroots level, and creating a
                    vibrant community of supporters.
                  </p>
                  <p>
                    Through strategic partnerships and concerted efforts, we are
                    committed to not only showcasing the incredible athleticism
                    and strategy inherent in Kabaddi but also instilling a
                    deep-seated love for the sport among fans across Uttar
                    Pradesh and beyond.
                  </p>
                  <p>
                    Join us on this exhilarating journey as we embark on a quest
                    to make Kabaddi a household name and a source of pride for
                    generations to come.
                  </p>

                  <div className="founderline"></div>
                  <div className="designation">
                    <h5>SAMBHAV JAIN</h5>
                    <h6>DIRECTOR & CEO</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
