import React, { useState, useEffect } from "react";
import arrow from "../arrow.svg";
import tp3 from "../Teamimg/table (3).svg";
import tv from '../teambg.svg'

function StandingsTable() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/Standings?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []); 

  return (
    <>
      <section className="pointtable">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">POINTS TABLE</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow} className="textrightarrow" />
            </div>{" "}
          </div>
        </div>

        <div className="container">
          <div className="pointer-table">
            <div className="tablecontainer">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center">S No.</th>
                    <th></th>
                    <th>TEAMS</th>
                    <th>P </th>
                    <th>W </th>
                    <th>L </th>
                    <th className="mobilenone">T </th>
                    <th className="mobilenone">+/-</th>
                    <th className="mobilenone">PTS</th>
                    <th className="text-center mobilenone">FORM</th>
                  </tr>
                </thead>
                <tbody>
                  {standings.length > 0 ? (
                    standings.map((team, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          <img
                            src={`https://dev-upkl.upkl.in/${team.image}`}
                            alt={team.team}
                          />
                        </td>{" "}
                        <td>{team.team}</td>
                        <td>{team.points}</td>
                        <td>{team.win}</td>
                        <td>{team.loss}</td>
                        <td className="mobilenone">{team.tie}</td>
                        <td className="mobilenone">{team.points}</td>
                        <td className="mobilenone">{team.points}</td>
                        <td className="mobilenone">
                          <ul>
                            <li className="win">W</li>
                            <li className="loss">L</li>
                            <li className="win"> W</li>
                            <li className="win">W</li>
                            <li className="loss">L</li>
                          </ul>
                        </td>
                        {/* Render other data accordingly */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">Loading...</td>
                      <td colSpan="3"></td>
<td className="mobilenone"></td>
<td className="mobilenone"></td>
<td className="mobilenone"></td>
<td className="mobilenone"></td>


                    
                      
                    </tr>
                  )}
                </tbody>
              </table>{" "}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StandingsTable;
