import React, { useState, useEffect } from 'react';
import Footer from "../Include/Footer1";
import Nav from "../Include/Nav";
import arrow1 from '../arrow.svg'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function Team() {

  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch('https://dev-upkl.upkl.in/api/resource/Teams?fields=["*"]');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        const sortedData = data.data.sort((a, b) => a.position - b.position);
        setStandings(sortedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []); // Empty dependency array means this effect runs only once, after the initial render


  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";
  return (
    <>

    <Nav/>
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL TEAM</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" />
            </div>{" "}
          </div>
        </div>
      </section>

      <section className="teamsection">
  <div className="container">
    <div className="row">


    {standings.length > 0 ? (
  standings.map((team, index) => (
//     <tr  key={index}>
//       <td className="text-center">{index + 1}</td>
// <td>
// <img src={`https://dev-upkl.upkl.in/${team.image}`} alt={team.team} />
// </td>      <td>{team.team}</td>
//       <td>{team.points}</td>
//       <td>{team.win}</td>
//       <td>{team.loss}</td>
//       <td  className='mobilenone'>{team.tie}</td>
//       <td  className='mobilenone'>{team.points}</td>
//       <td  className='mobilenone'>{team.points}</td>
//        <td className='mobilenone'>
//             <ul>
//             <li className='win'>W</li>
//               <li className='loss'>L</li>
//               <li className='win'>  W</li>
//               <li className='win'>W</li>
//               <li className='loss'>L</li>
//             </ul>
//           </td>
//       {/* Render other data accordingly */}
//     </tr>


<div className="col-md-3 col-12">
<div className="teamcard">
<div className="topsection topsection1">
<img src={`https://dev-upkl.upkl.in/${team.team_background}`} className="img-fluid" />
<center><div class="overlayplayer">{team.team_name}</div></center>
</div>
<div className="bottomplaysection">
<img src={`https://dev-upkl.upkl.in/${team.team_logo}`} className="img-fluid"/>
<div className="row">
<div className="d-flex btnteams">
  <button>VIEW PROFILE</button>
  <button>VIEW MATCHES</button>
</div>
</div>
</div>
</div>



</div>


  ))
) : (
  
  <SkeletonTheme baseColor="#292679" highlightColor="#39358b70">

  <div  className="image-container">
  <div className="overlay">
  </div>
  <Skeleton width={240} height={300} /> {/* Adjust width and height as per your design */}
</div>
</SkeletonTheme>
)}


     
      
    </div>
  </div>


</section>

<Footer/>

    </>
  );
}

export default Team;
