import React, { useState, useEffect } from "react";
import Nav from '../Include/Nav'
import blogsection from '../blogsection.svg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import news from '../newsletter.svg'
import newleft from '../newsletter-img.png'
import tv from '../teambg.svg'
import Footer from '../Include/Footer1'
import fb from "../fb.png";
import inst from "../instagram.png";
import you from "../you-tube.png";
import twitter from "../twitter.png";
import arrow from '../arrow.svg'


export default function SingleBlog() {

  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    const fetchStandings = async () => {
      try {

        const params = new URLSearchParams(window.location.search);
                const id = params.get('id');
        const response = await fetch(`https://dev-upkl.upkl.in/api/resource/UPKL%20Blog/${id}?fields=["*"]`);
        console.log(response,"aas")
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  },); 


  
    const options3 = {
        loop: true,
        margin: 20,
        nav: true,
        loop:true,
        autoplay: true, // Enable autoplay
        autoplayTimeout: 4000,
        responsive: {
          0: {
            items: 1.6,
    
          },
          600: {
            items: 3
          },
          1000: {
            items: 4
          }
        }
      };
  return (
    <>
      <Nav/>

      <div className='singleblog'>
        <div className='row'>
        <div className='blogbanner'>
            <img  src={`https://dev-upkl.upkl.in/${standings.banner_image}`} className='img-fluid' />
        </div>


        <div className='blog-details'>
            <div className='col-md-8 col-12 offset-md-2'>
                <div className='rw'>
<div className='blogheadng '>
    <h2>{standings.title}  <img src={arrow} className='arrow img-fluid'/></h2>
</div>

<div className="blogline"></div>

<div className='row p-0'>

            <div className='col-md-6 col-6'>
                <p className='blogdate'>Sunday May 29, 2024 </p>
            </div>
            <div className='col-3 mobilenone'>
            </div>
            <div className='col-md-3 col-6 d-flex'>
                <p className='blogshareicon'>Social
                 </p>
                 <div className="footerrightmenu">
                 <div className="bloglogo">
                  <ul className="d-flex pl-0">
                    <li className="">
                      <img src={fb} />
                    </li>
                    <li>
                      <img src={inst} />
                    </li>
                    <li>
                      <img src={you} />
                    </li>
                    <li>
                      <img src={twitter} />
                    </li>
                  </ul>
                </div>
                </div>
            </div>
            </div>
<div className='blogpara p-0'>
   <p  dangerouslySetInnerHTML={{
                                        __html: standings.blog_description,
                                      }}>
</p>
</div>
                </div>
            </div>
        </div>
        </div>
      </div>



      <section className='tvsection'>

<div className='titlecaption'>
  <h2>LATEST NEWS</h2>
  <div className='linetilte'></div>
</div>

<OwlCarousel className="owl-theme" {...options3}>

  <div class="card">
  <img class="card-img-top" src={tv} alt="Card image cap"/>
  <div class="card-body">
  <p class="card-text">Noida Ninjas <span className='text-white datetv'>| 21:00, Saudi Arabia</span></p>

    <h5 class="card-title">Noida Ninjas In Semi Finals And Defeating Challengers</h5>
  </div>
</div>

</OwlCarousel>


<div className='newsbottom'>
<div className='row'>
  <div className='col-md-6 col-12'>

  <div class="image-container">
  <div class="overlay">

    <div class="text"><h5>Engaging and Enthralling - Best Matches from UPKL Teams 2024</h5>
    <div className='divider'></div>
    <p>May 19, 2024</p>
    
    </div>
  </div>
  <img src={news} alt="Your Image" class="image"/>
</div>
  </div>
  <div className='col-md-6 col-12'>
    <div className='newlist'>
      <div className='row'>
        <div className='col-md-3 col-4'>
          <div className='leftimgnews'>
            <img src={newleft} className='img-fluid' />
          </div>
        </div>
        <div className='col-md-9 col-8'>
         <div className='rightcontentnew'>
         <div className='new-title'>
            <h6>Engaging and Enthralling - Best Matches from UPKL Teams 2024 Season</h6>
<div className='bottompara'>
<p>Noida Ninjas <span>| 21:00, Saudi Arabia</span></p>

</div>
          </div>
         </div>
        </div>

      </div>
    </div>

    <div className='newlist'>
      <div className='row'>
        <div className='col-md-3 col-4'>
          <div className='leftimgnews'>
            <img src={newleft} className='img-fluid' />
          </div>
        </div>
        <div className='col-md-9 col-8'>
         <div className='rightcontentnew'>
         <div className='new-title'>
            <h6>Engaging and Enthralling - Best Matches from UPKL Teams 2024 Season</h6>
<div className='bottompara'>
<p>Noida Ninjas <span>| 21:00, Saudi Arabia</span></p>

</div>
          </div>

        
         </div>
        </div>

      </div>
    </div>


    <div className='newlist'>
      <div className='row'>
        <div className='col-md-3 col-4'>
          <div className='leftimgnews'>
            <img src={newleft} className='img-fluid' />
          </div>
        </div>
        <div className='col-md-9 col-8'>
         <div className='rightcontentnew'>
         <div className='new-title'>
            <h6>Engaging and Enthralling - Best Matches from UPKL Teams 2024 Season</h6>
<div className='bottompara'>
<p>Noida Ninjas <span>| 21:00, Saudi Arabia</span></p>

</div>
          </div>
         </div>
        </div>

      </div>
    </div>


  </div>

</div>
</div>
  </section> 

<Footer/>
    </>
  )
}
