import React,{useState,useEffect } from 'react'
import logo from '../logoup.svg'
import { Link, useLocation  } from 'react-router-dom'
import arrow from './arrowpreheader.svg'

export default function Nav() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  return (
   <>
   
   <div className='preheader mobilenone'>
    <div className='row'>
      <div className='col-4 align-self-center'>
        <div className='preheadingtext'>
          <p>Brij stars to play semi final match MAY 19 2024, SUNDAY  <img src={arrow} className='img-fluid float-right'/></p>
        </div>
      </div>
      <div className='col-4 align-self-center'>
      <div className='preheadingtext'>
      <p>Brij stars to play semi final match MAY 19 2024, SUNDAY  <img src={arrow} className='img-fluid float-right'/></p>
        </div>
      </div>
      <div className='col-4 align-self-center'>
      <div className='preheadingtext'>
      <p>Brij stars to play semi final match MAY 19 2024, SUNDAY </p>
        </div></div> </div>
    </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light mobilenone">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className={location.pathname === '/matches' ? 'active nav-link' : 'nav-link'}  to="/matches">MATCHES</Link>
              </li>
              <li className="nav-item">
                <Link className={location.pathname === '/standing' ? 'active nav-link' : 'nav-link'}  to="/standing">STANDINGS</Link>
              </li>
              <li className="nav-item">
                <Link className={location.pathname === '/team' ? 'active nav-link' : 'nav-link'}  to="/team">TEAMS</Link>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#">PLAYERS</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#">UPKL TV</a>
              </li>
            </ul>
            <Link className="navbar-brand mx-auto" to="/">
          <img src={logo} alt="Center Logo" className='img-fluid upkllogo mobilenone' />
            </Link>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#">TROPHY TOUR</a>
              </li>
              <li className="nav-item">
              <Link className={location.pathname === '/blog-list' ? 'active nav-link' : 'nav-link'}  to="/blog-list"> BLOG</Link>
              </li>
              <li className="nav-item">

                <Link className={location.pathname === '/about' ? 'active nav-link' : 'nav-link'} to="/about"> ABOUT</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"> SPONSORS</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"> SPORTS AMBASSADOR</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

     
      <nav  className="navigation desktopnone navbar" role=" ">
            <div id="menuToggle">
              <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
            <ul id="menu">
            <li  className={`nav-item ${location.pathname === '/matches' ? 'active' : ''}`}>
                <Link className="nav-link" to="/matches">MATCHES</Link>
              </li>
              <li  className={`nav-item ${location.pathname === '/standing' ? 'active' : ''}`}>
              <Link className="nav-link" to="/standing">STANDINGS</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/team">TEAMS</Link>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#">PLAYERS</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#">UPKL TV</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">TROPHY TOUR</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"> NEWS</a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">ABOUT</Link>

              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"> SPONSORS</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"> SPORTS AMBASSADOR</a>
              </li>
            </ul>
           </div>
           <div className='mblogo'>
          <Link to="/"> <img src={logo}/></Link>  </div>
          </nav>
   </>

  )
}
