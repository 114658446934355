import React, { useState, useEffect } from "react";
import arrow1 from "../arrow.svg";
import Nav from "../Include/Nav";
import arrownlblog from "../arrownlblog.svg";
import { Link } from "react-router-dom";
import Footer from "../Include/Footer1";
import border from "../borderblog.svg";
export default function Bloglist() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/UPKL%20Blog?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const breadcumb = "https://dev-upkl.upkl.in//files/breadcumb1.png";

  return (
    <>
      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">UPKL BLOG</h2>
              <div className="linetilte"></div>
            </div>
            <div className="abc">
              <img src={arrow1} className="textrightarrow" />
            </div>{" "}
          </div>
        </div>
      </section>

      <section className="bloglist">
        <div className="container">
          <div className="row">
            {standings.length > 0 ? (
              standings.map((team, index) => (
                <div className="col-md-3 col-12">
                  <Link to={`/single-blog?id=${team.name}`}>
                    <div class="card">
                      <img src={border} className="img-fluid bordernew" />
                      <img
                        class="card-img-top"
                        src={`https://dev-upkl.upkl.in/${team.card_image}`}
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <p class="card-text">
                          Noida Ninjas{" "}
                          <span className="text-white datetv">
                            | 21:00, Saudi Arabia
                          </span>
                        </p>

                        <h5 class="card-title">
                          {team.title.split(" ").slice(0, 8).join(" ")}{" "}
                          {/* Extract first 30 words */}
                          {team.title.split(" ").length > 30 ? "..." : ""}{" "}
                          {/* Add ellipsis if title exceeds 30 words */}
                          ..
                        </h5>

                        <div className="readmore">
                          <div className="row">
                            <div className="col-2 align-self-center">
                              <Link to={`/single-blog?id=${team.name}`}>
                                {" "}
                                <button>
                                  <img src={arrownlblog} />
                                </button>
                              </Link>
                            </div>
                            <div className="col-6 align-self-center">
                              <p>&nbsp;&nbsp;2 min Read</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p>Loading..</p>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
